
import * as unsplashRuntime$qyX4HdqdK3 from '/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/@nuxt+image@1.8.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.19.0/node_modules/@nuxt/image/dist/runtime/providers/unsplash'
import * as ipxRuntime$74gsvpaSG8 from '/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/@nuxt+image@1.8.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.19.0/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "thumbnail": {
      "modifiers": {
        "fit": "cover",
        "width": 75,
        "height": 75,
        "format": "avif"
      }
    },
    "listingSingleLogo": {
      "modifiers": {
        "fit": "contain",
        "width": 200,
        "height": 200,
        "format": "avif"
      }
    },
    "logo": {
      "modifiers": {
        "fit": "contain",
        "width": 250,
        "height": 250,
        "format": "avif"
      }
    },
    "icon": {
      "modifiers": {
        "fit": "inside",
        "width": 50,
        "height": 50,
        "format": "png"
      }
    },
    "banner": {
      "modifiers": {
        "fit": "cover",
        "width": 1920,
        "height": 300,
        "format": "avif",
        "position": "center"
      }
    }
  },
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "avif",
    "webp"
  ]
}

imageOptions.providers = {
  ['unsplash']: { provider: unsplashRuntime$qyX4HdqdK3, defaults: undefined },
  ['ipx']: { provider: ipxRuntime$74gsvpaSG8, defaults: {} }
}
        